<template>
    <label class="block text-sm font-medium text-gray-700">
        <template v-if="value">
            {{ value }}
        </template>
        <slot v-else />
    </label>
</template>

<script>
export default {
    props: {
        value: {type:String},
    },
};
</script>
